import React, { useRef, useEffect } from 'react'
import styled from '@emotion/styled'
import gsap from 'gsap'

import Page from '../templates/Page'
import Seo from '../components/seo'
import { useInViewportAnimation, useOnScreen } from '../utils/hooks'
import {
  H2,
  Hero,
  Text,
  breakpoints,
  AlternatingTwoThirdsGrid,
  TwoColumnGrid,
  BigSmallColumn,
  Container,
  Button,
} from '../utils/styles'
import { StaticImage } from 'gatsby-plugin-image'
import { colors, space } from '../utils/theme'

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(1, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  @media (max-width: ${breakpoints.l}px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
`

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const ImageTitle = styled.h3`
  z-index: 2;
  position: absolute;
  font-weight: 400;
  padding: ${space[2]};
  background-color: ${colors.hergaBlue};
  color: white;
`

const UnternehmenPage = () => {
  const gridRef = useRef()
  const gridIsVisible = useOnScreen(gridRef, '-50px')
  const animationPlayed = useRef(false)

  useInViewportAnimation()

  useEffect(() => {
    if (gridIsVisible && !animationPlayed.current) {
      gsap.from('#grid > *', {
        duration: 1,
        opacity: 0,
        stagger: 0.5,
        ease: 'power2.in',
      })
      animationPlayed.current = true
    }
  }, [gridIsVisible])

  return (
    <Page>
      <Seo title="Unternehmen" />
      <Hero>IHR INNOVATIVER SPEZIALIST FÜR KONSTRUKTION</Hero>
      <Text>
        Das Konstruktionsbüro Herga ist seit 1991 auf Maschinenbau
        spezialisiert. Zu unseren Kunden gehören vor allem Unternehmen aus der
        Stanztechnik, dem Spritzguss und dem allgemeinem Maschinenbau.
        <br />
        <br /> Durch die flache Hierarchie und unsere schlanke
        Unternehmensstruktur sind wir in der Lage dynamisch und flexibel auf
        Veränderungen zu reagieren.
      </Text>

      <H2 style={{ marginTop: space[20] }}>Unsere Betätigungsfelder</H2>
      <Grid ref={gridRef} data-inviewport="fade-in" id="grid">
        <ImageWrapper>
          <ImageTitle>Allgemeiner Maschinenbau</ImageTitle>
          <StaticImage
            src="../images/cnc_fraese.jpg"
            layout="fullWidth"
            alt="CNC Fräse Herga Konstruktionsbüro"
            placeholder="tracedSVG"
          />
        </ImageWrapper>

        <ImageWrapper>
          <ImageTitle>Artikelkonstruktion</ImageTitle>
          <StaticImage
            src="../images/3d_drucker.jpg"
            layout="fullWidth"
            alt="Fräsköpfe Herga Konstruktionsbüro"
            placeholder="tracedSVG"
          />
        </ImageWrapper>

        <ImageWrapper>
          <ImageTitle>Greiferbau</ImageTitle>
          <StaticImage
            src="../images/JK_2.jpg"
            layout="fullWidth"
            alt="Greiferbau"
            placeholder="tracedSVG"
          />
        </ImageWrapper>

        <ImageWrapper>
          <ImageTitle>Roboter Einbindung</ImageTitle>
          <StaticImage
            src="../images/possessed-photography-dRMQiAubdws-unsplash.jpg"
            alt="Roboter in der Fertigungslinie"
            layout="fullWidth"
            placeholder="tracedSVG"
          />
        </ImageWrapper>
      </Grid>
           
      <BigSmallColumn style={{ marginTop: space[20], backgroundColor: colors.grey, padding: space[6], paddingBottom: space[10],alignItems: "center"}} gap="0">
        <div>
          <H2>BEISPIELE  UNSERER ARBEIT</H2>
          <Text>Klicken Sie hier, um ausgewählte Beispiele unserer Arbeit zu sehen!</Text>
        </div>

        <div data-inviewport="fade-in-right">
        <a href="/arbeitsbeispiele" target="_self" rel="noreferrer" style={{textDecoration: 'none'}}>
        <Button backgroundColor={colors.hergaBlue} style={{ color: 'white'}}>
              Beispiele ansehen
          </Button>
          </a>

        </div>
      </BigSmallColumn>
      
      <BigSmallColumn style={{ marginTop: space[20] }} gap="0">
        <div style={{ backgroundColor: colors.pearlBush, padding: space[6] }}>
          <H2>KLUGE KÖPFE MIT LANGJÄHRIGER ERFAHRUNG</H2>
          <Text>
            Unser Team besteht aus drei Maschinenbau Technikern, einem
            Mechatroniker sowie einer technischen Zeichnerin. Bevor er sich 1991
            mit seinem Unternehmen selbstständig machte, war Karl Herga
            jahrelang Konstrukteur für Stanzwerkzeuge und entwickelte
            CAD-Systeme weiter.
          </Text>
        </div>

        <div data-inviewport="fade-in-right">
          <StaticImage
            src="../images/karl_herga.png"
            alt="Karl Herga"
            layout="fullWidth"
            placeholder="tracedSVG"
          />
        </div>
      </BigSmallColumn>

      <TwoColumnGrid
        style={{ marginTop: space[20], marginBottom: space[20] }}
        gap="0"
      >
        <StaticImage
          src="../images/herga_katalog.jpg"
          alt="Herga Katalog"
          layout="fullWidth"
          placeholder="tracedSVG"
        />

        <div style={{ backgroundColor: colors.grey, padding: space[6] }}>
          <H2>UNSERE MEILENSTEINE SEIT 1991</H2>
          <Text>
            <b>
              Auf unserem Weg seit der Gründung, haben wir viele Meilensteine
              gesetzt. Die wichtigsten finden Sie hier.
            </b>
            <br />
            <br />
            <b>1991</b>
            <br /> Gründung des Konstruktionsbüro Herga für Formen und
            Werkzeugbau
            <br />
            <br />
            <b>1992</b>
            <br /> Einstellung des ersten Mitarbeiters
            <br />
            <br /> <b>1998</b> <br />
            Eingliederung des Konstruktionsbereichs Maschinenbau
            <br />
            <br /> <b>2007</b> <br />
            Eröffnung einer Werkstatt zum Montieren von Greifsystemen.
            Entwicklung und Umsetzung unseres Leichtbau-Saugsystems
            <br />
            <br />
            <b>2009</b> <br />
            Umzug in eine größere Werkstatt mit Lagererweiterung und Versand
            <br />
            <br />
            <b>2012</b>
            <br /> Umzug mit der Werkstatt nach Fürth in den Complex Gewerbehof.
            Die Werkstatt das Lager und der Versand haben jetzt eine Größe von
            220 m².
            <br />
            <br /> <b>2019</b>
            <br /> UR Universal Robots zertifizierter Lieferant für EOAT (End Of
            Arm Tool)
          </Text>
        </div>
      </TwoColumnGrid>
    </Page>
  )
}

export default UnternehmenPage
